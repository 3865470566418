import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
    ThemeProvider,
    createTheme,
    responsiveFontSizes,
} from "@mui/material/styles";
import {
    RiAlertLine,
    RiCheckboxCircleLine,
    RiErrorWarningLine,
    RiInformationLine,
} from "@remixicon/react";
import React, {
    ComponentType,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { Empty } from "./Common/Empty";
import { handlePromiseError } from "./Common/JavascriptErrorHandling";
import Loading from "./Common/Loading";
import Maybe from "./Common/Maybe";
import { getLoginUrl, getMgmtUrl } from "./Common/Urls";
import { wrappedJsonFetch } from "./Common/WrappedFetch";
import { TestingMenu } from "./TestingMenu";

// Login pages will be used by much more often than the management pages.
// We don't want the javascript for management to be downloaded by users
// that are only logging in.  The lazy statements below cause the bundler
// to perform code splitting.
const lazyRetry = (componentInport: () => any, name: string) =>
    new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
        const sessionName = `tried-${name}-reload`;
        const hasRefreshed = window.sessionStorage.getItem(sessionName);
        componentInport()
            .then((component: any) => {
                if (hasRefreshed != null) {
                    window.sessionStorage.removeItem(sessionName);
                }
                resolve(component);
            })
            .catch((error: any) => {
                if (hasRefreshed == null) {
                    console.log("Chunk load error detected, reloading");
                    window.sessionStorage.setItem(sessionName, "true");
                    window.location.reload();
                } else {
                    console.log("Chunk load error detected, skipping reload");
                    reject(error);
                }
            });
    });

const LoginHome = React.lazy(() =>
    lazyRetry(() => import("./Login/LoginHome"), "login")
);
const ExternalLogin = React.lazy(() =>
    lazyRetry(() => import("./Login/ExternalLogin"), "external")
);
const ManagementHome = React.lazy(() =>
    lazyRetry(() => import("./Management/ManagementHome.js"), "management")
);
const ReportPrint = React.lazy(() =>
    lazyRetry(() => import("./Management/PrintReport"), "print")
);

declare module "@mui/material/styles" {
    interface Theme {
        textBox: { background?: string; autofill: string };
        alert: {
            error: { background: string; text: string };
            success: { background: string; text: string };
            warning: { background: string; text: string };
            info: { background: string; text: string };
        };
        mode: PaletteType;
        layout: "new" | "old";
        environment: "" | "test";
        logoColor: string;
    }
    interface PaletteOptions {
        textBox: { background?: string; autofill?: string };
        alert: {
            error: { background: string; text: string };
            success: { background: string; text: string };
            warning: { background: string; text: string };
            info: { background: string; text: string };
        };
        mode?: PaletteType;
        layout: "new" | "old";
        environment: "" | "test";
        logoColor: string;
        tertiary: PaletteOptions["primary"];
    }
    interface Palette {
        tertiary: Palette["primary"];
    }
}
declare module "@mui/material/Checkbox" {
    interface CheckboxPropsColorOverrides {
        tertiary: true;
    }
}
declare module "@mui/material/CircularProgress" {
    interface CircularProgressPropsColorOverrides {
        tertiary: true;
    }
}

type Environment = { environment: "test" | ""; color: string | null };

function numberStringToMs(
    number: string | number | undefined | null,
    defaultValue: string
) {
    if (number == null) return defaultValue;
    if (Number.isInteger(number)) return number + "ms";
    return number;
}

const getTheme = (
    mode: PaletteType,
    environment: Environment,
    newLayout: boolean,
    menuOpen: boolean
) => {
    const defaultTheme = createTheme();
    const background = mode === "dark" ? "#212121" : "#FFFFFF";
    const text =
        mode === "dark"
            ? {
                  primary: "rgba(255,255,255,0.95)",
                  secondary: "rgba(255,255,255,0.65)",
                  tertiary: "rgba(255,255,255,0.65)",
                  disabled: "rgba(245,245,245,0.35)",
              }
            : {
                  primary: "rgba(0, 0,0,0.95)",
                  secondary: "rgba(0,0,0,0.65)",
                  tertiary: "rgba(0,0,0,0.65)",
                  disabled: "rgba(0,0,0,0.35)",
              };
    const alertInfo =
        mode === "dark"
            ? {
                  error: {
                      background: "rgb(22, 7, 8)",
                      text: "rgb(243, 183, 187)",
                  },
                  success: {
                      background: "rgb(7, 19, 12)",
                      text: "rgb(183, 231, 202)",
                  },
                  warning: {
                      background: "rgb(23, 16, 6)",
                      text: "rgb(247, 218, 178)",
                  },
                  info: {
                      background: "rgb(7, 19, 24)",
                      text: "rgb(184, 231, 251)",
                  },
              }
            : {
                  error: {
                      background: "rgb(252, 237, 238)",
                      text: "rgb(90, 30, 34)",
                  },
                  success: {
                      background: "rgb(237, 249, 241)",
                      text: "rgb(30, 78, 49)",
                  },
                  warning: {
                      background: "rgb(253, 245, 235)",
                      text: "rgb(94, 65, 25)",
                  },
                  info: {
                      background: "rgb(229, 246, 253)",
                      text: "rgb(1, 67, 97)",
                  },
              };
    const baseColors = {
        error: "#DA1E2B",
        success: "#21B65C",
        warning: "#E88E0F",
        primary: newLayout
            ? mode === "dark"
                ? "#4286F8"
                : "#0F5DDD"
            : mode === "dark"
            ? "#199EE5"
            : "#253670",
    };
    return responsiveFontSizes(
        createTheme({
            transitions: {
                create: (
                    props: string | string[],
                    options?: Partial<{
                        duration: number | string;
                        easing: string;
                        delay: number | string;
                    }>
                ) => {
                    let transitionValue;
                    if (Array.isArray(props)) {
                        transitionValue = props
                            .filter(
                                (x) => x !== "color" && x !== "background-color"
                            )
                            .map(
                                (x) =>
                                    `${x} ${numberStringToMs(
                                        options?.duration,
                                        "3s"
                                    )} ${
                                        options?.easing ?? ""
                                    } ${numberStringToMs(options?.delay, "")}`
                            )
                            .join(",");
                    } else if (
                        props !== "color" &&
                        props !== "background-color"
                    ) {
                        transitionValue = `${props} ${numberStringToMs(
                            options?.duration,
                            "3s"
                        )} ${options?.easing ?? ""} ${numberStringToMs(
                            options?.delay,
                            ""
                        )}`;
                    }
                    if (transitionValue == null)
                        return "background-color 200ms,color 200ms";
                    return `${transitionValue},background-color 200ms,color 200ms`;
                },
            },
            palette:
                mode === "dark"
                    ? {
                          mode: "dark",
                          layout: newLayout ? "new" : "old",
                          primary: { main: "#4286F8", contrastText: "#FFFFFF" },
                          secondary: {
                              main: "#9BA1B5",
                              contrastText: "#000000",
                          },
                          tertiary: defaultTheme.palette.augmentColor({
                              color: {
                                  main: "#1B1B2F",
                                  dark: "#1F1F3F",
                                  contrastText: "#FFFFFF",
                              },
                              name: "tertiary",
                          }),
                          warning: {
                              main: baseColors.warning,
                              contrastText: "#000000",
                          },
                          error: {
                              main: baseColors.error,
                              contrastText: "#000000",
                          },
                          success: {
                              main: baseColors.success,
                              contrastText: "#000000",
                          },
                          background: {
                              default: newLayout ? "#1B1B1B" : background,
                              paper: background,
                          },
                          textBox: {
                              background: newLayout
                                  ? "#1B1B1B"
                                  : alertInfo.info.background,
                              autofill: "rgb(6, 83, 119)",
                          },
                          text: text,
                          environment: environment.environment,
                          logoColor:
                              environment.color ??
                              (newLayout ? "#4286F8" : "#253670"),
                          alert: alertInfo,
                      }
                    : {
                          mode: "light",
                          layout: newLayout ? "new" : "old",
                          primary: {
                              main: newLayout ? "#0F5DDD" : "#004E7b",
                              contrastText: "#FFFFFF",
                          },
                          secondary: {
                              main: newLayout ? "#494F5B" : "#9BA1B5",
                              contrastText: "#FFFFFF",
                          },
                          tertiary: defaultTheme.palette.augmentColor({
                              color: {
                                  main: "#EBF3FF",
                                  dark: "#D0E2FE",
                                  //light: "#9EC4FF",
                                  contrastText: "#000000",
                              },
                              name: "tertiary",
                          }),
                          warning: {
                              main: baseColors.warning,
                              contrastText: "#FFFFFF",
                          },
                          error: { main: baseColors.error },
                          success: {
                              main: baseColors.success,
                              contrastText: "#FFFFFF",
                          },
                          background: {
                              default: newLayout ? "#FAFBFC" : background,
                              paper: background,
                          },
                          textBox: {
                              background: newLayout
                                  ? "#F3F5F7"
                                  : alertInfo.info.background,
                              autofill: "rgb(193, 233, 255)",
                          },
                          text: text,
                          environment: environment.environment,
                          logoColor:
                              environment.color ??
                              (newLayout ? "#004CFF" : "#253670"),
                          alert: alertInfo,
                      },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: menuOpen ? 757 : 500,
                    md: menuOpen ? 957 : 700,
                    lg: menuOpen ? 1217 : 960,
                    xl: menuOpen ? 1536 : 1279,
                },
            },
            typography: {
                fontFamily: [
                    "Inter",
                    "-apple-system",
                    "BlinkMacSystemFont",
                    "Segoe UI",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "Noto Sans",
                    "sans-serif",
                    "Apple Color Emoji",
                    "Segoe UI Emoji",
                    "Segoe UI Symbol",
                    "Noto Color Emoji",
                ]
                    .filter((x) => newLayout || x !== "Inter")
                    .join(","),
            },
            components: {
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            background: `${background} !important`,
                            color: `${text.primary} !important`,
                        },
                    },
                },
                MuiButton: {
                    defaultProps: {
                        color: "primary",
                    },
                    styleOverrides: {
                        root: {
                            textTransform: newLayout ? "none" : undefined,
                            "&.DoNotFade": {
                                color: `${text.primary} !important`,
                            },
                        },
                        contained: {
                            ":hover": {
                                boxShadow: "unset",
                            },
                            boxShadow: "unset",
                        },
                    },
                },
                MuiTableFooter: {
                    styleOverrides: {
                        root: {
                            background: `${background} !important`,
                            color: `${text.primary} !important`,
                        },
                    },
                },
                MuiPaper: {
                    defaultProps: {
                        variant: newLayout ? "outlined" : undefined,
                    },
                },
                MuiContainer: {
                    defaultProps: {
                        disableGutters: true,
                        maxWidth: newLayout ? false : undefined,
                        sx: {
                            paddingLeft: "0",
                            paddingRight: "0",
                        },
                    },
                },
                MuiFormControlLabel: {
                    styleOverrides: {
                        label: {
                            color: `${text.primary} !important`,
                            "&.Mui-disabled": {
                                color: `${text.primary} !important`,
                            },
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        root: {
                            "&:hover": {
                                background:
                                    mode === "dark" ? "#001F68" : "#E0ECFE",
                            },
                            "&:hover .Mui-disabled": {
                                background: background,
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "4px",
                                borderColor:
                                    mode === "dark"
                                        ? "rgba(255, 255, 255, 0.23)"
                                        : "rgba(0, 0, 0, 0.23)",
                            },
                            "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderRadius: "4px",
                                    borderColor: baseColors.primary,
                                },
                            "&.Mui-error:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderRadius: "4px",
                                    borderColor: baseColors.error,
                                },
                        },
                    },
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            color: `${text.primary} !important`,
                            "&.Mui-disabled": {
                                color: `${text.primary} !important`,
                            },
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            color: `${text.primary} !important`,
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        label: {
                            marginBottom: "auto",
                            marginTop: "auto",
                        },
                    },
                },
                MuiAlert: {
                    defaultProps: {
                        iconMapping: {
                            info: newLayout ? <RiInformationLine /> : undefined,
                            success: newLayout ? (
                                <RiCheckboxCircleLine />
                            ) : undefined,
                            warning: newLayout ? <RiAlertLine /> : undefined,
                            error: newLayout ? (
                                <RiErrorWarningLine />
                            ) : undefined,
                        },
                    },
                },
                MuiTablePagination: {
                    styleOverrides: {
                        toolbar: {
                            minHeight: newLayout
                                ? "33.0167px !important"
                                : undefined,
                        },
                        selectLabel: {
                            marginTop: ".75rem",
                            marginBottom: ".75rem",
                        },
                        displayedRows: {
                            marginTop: ".75rem",
                            marginBottom: ".75rem",
                        },
                    },
                },
                MuiLink: {
                    defaultProps: {
                        color: "primary",
                    },
                    styleOverrides: {
                        root: {
                            //fontWeight: 500,
                            //color: "#004CFF",
                            //textDecoration: "underline #004CFF",
                        },
                    },
                },
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiIconButton: {
                    defaultProps: {
                        color: "primary",
                    },
                },
            },
        })
    );
};

const globalStyles = (
    <GlobalStyles
        styles={{
            body: {
                fontFamily: [
                    "Inter",
                    "-apple-system",
                    "BlinkMacSystemFont",
                    "Segoe UI",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "Noto Sans",
                    "sans-serif",
                    "Apple Color Emoji",
                    "Segoe UI Emoji",
                    "Segoe UI Symbol",
                    "Noto Color Emoji",
                ].join(","),
                "@keyframes spin": {
                    from: "transform: rotate(0deg)",
                    to: "transform: rotate(360deg);",
                },
                ".spin": {
                    animation: "2s linear infinate spin",
                },
            },
            // Force Edge to not show its own show/hide password button
            // PasswordField should be used and it has a custom one
            "::-ms-clear": { display: "none" },
            "::-ms-reveal": { display: "none" },
        }}
    />
);

const colorSchemeStorageKey = "prefers-color-scheme";
type PaletteType = "light" | "dark";

function getColor(): PaletteType {
    const modeFromStorage = localStorage.getItem(colorSchemeStorageKey);
    if (modeFromStorage != null) {
        return modeFromStorage === "dark" ? "dark" : "light";
    }
    return "light";
}

function RedirectToManagement() {
    useEffect(() => {
        async function redirectToMgmt() {
            const url = new URL("/Management", await getMgmtUrl());
            window.location.replace(url);
        }
        redirectToMgmt().catch((error) =>
            handlePromiseError(error).then((p) => {
                throw new Error(p != null ? "__" + p : "Unknown Error");
            })
        );
    }, []);
    return <Empty />;
}

function ManagementRoute(props: { element: React.JSX.Element }) {
    const [element, setElement] = useState<React.JSX.Element>(<Empty />);
    useEffect(() => {
        async function checkMgmtRoute() {
            const regex = new RegExp(
                `^${await getLoginUrl()}(/Management(/.*)?$)`
            );
            const result = regex.exec(window.location.href);
            if (result?.[1] != null) {
                window.location.replace(`${await getMgmtUrl()}${result[1]}`);
            }
            setElement(props.element);
        }
        checkMgmtRoute().catch((error) =>
            handlePromiseError(error).then((p) => {
                throw new Error(p != null ? "__" + p : "Unknown Error");
            })
        );
    }, [props.element]);
    return element;
}

export default function App() {
    const cache = createCache({
        key: "dlrsecured",
        nonce: (window as any).__nonce__,
        prepend: true,
    });
    const { pathname } = useLocation();
    const [paletteType, setPaletteType] = useState<PaletteType>(getColor());
    const [menuOpen, setMenuOpen] = useState<boolean>(
        localStorage.getItem("preferMenuOpen") !== "false"
    );
    const [env, setEnv] = useState<Environment>({
        environment: "",
        color: null,
    });
    const [version, setVersion] = useState<string | null>(null);
    const [otherEnv, setOtherEnv] = useState<Environment>({
        environment: "",
        color: null,
    });
    const [newLayout, setNewLayout] = useState(
        sessionStorage.getItem("newTheme") === "true"
    );
    const [theme, setTheme] = useState(
        getTheme(paletteType, env, newLayout, menuOpen)
    );
    const [testingMenuOpen, setTestingMenuOpen] = useState(false);
    const isManagement = window.location.pathname
        .toLowerCase()
        .startsWith("/management/");
    useEffect(() => {
        if (newLayout) document.documentElement.className = paletteType + "New";
        else document.documentElement.className = paletteType;
        async function checkEnv() {
            const response = await wrappedJsonFetch<any>(
                "/api/EnvironmentCheck"
            );
            if (response.ok) {
                const localEnv = response.json;
                if (localEnv.environment !== "") {
                    setEnv(localEnv);
                    setTheme(
                        getTheme(
                            paletteType,
                            localEnv,
                            newLayout,
                            isManagement && menuOpen
                        )
                    );
                    setVersion(localEnv.version);
                } else {
                    setVersion(localEnv.version);
                }
            }
        }
        checkEnv().catch((error) =>
            handlePromiseError(error).then((p) => {
                throw new Error(p != null ? "__" + p : "Unknown Error");
            })
        );
        // Run this once at startup, intentionally don't include paletteType in dependancies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setColor = useCallback(
        (mode: PaletteType) => {
            localStorage.setItem(colorSchemeStorageKey, mode);
            setPaletteType(mode);
            setTheme(getTheme(mode, env, newLayout, isManagement && menuOpen));
            if (newLayout) document.documentElement.className = mode + "New";
            else document.documentElement.className = mode;
        },
        [env, isManagement, menuOpen, newLayout]
    );
    const toggleColor = useCallback(
        (targetEnvName: "" | "test") => {
            if (targetEnvName === env.environment) return;
            setTheme(
                getTheme(
                    paletteType,
                    otherEnv,
                    newLayout,
                    isManagement && menuOpen
                )
            );
            const saved = env;
            setEnv(otherEnv);
            setOtherEnv(saved);
        },
        [env, isManagement, menuOpen, newLayout, otherEnv, paletteType]
    );
    const toggleNewTheme = useCallback(
        (enableNewLayout: boolean) => {
            if (enableNewLayout === newLayout) return;
            setTheme(
                getTheme(
                    paletteType,
                    env,
                    enableNewLayout,
                    isManagement && menuOpen
                )
            );
            setNewLayout(enableNewLayout);
            sessionStorage.setItem(
                "newTheme",
                enableNewLayout ? "true" : "false"
            );
            if (enableNewLayout)
                document.documentElement.className = paletteType + "New";
            else document.documentElement.className = paletteType;
        },
        [newLayout, paletteType, env, isManagement, menuOpen]
    );
    const toggleMenuOpen = useCallback(
        (newMenuOpen: boolean) => {
            if (newMenuOpen === menuOpen) return;
            setTheme(
                getTheme(
                    paletteType,
                    env,
                    newLayout,
                    isManagement && newMenuOpen
                )
            );
            localStorage.setItem(
                "preferMenuOpen",
                newMenuOpen ? "true" : "false"
            );
            setMenuOpen(newMenuOpen);
        },
        [newLayout, paletteType, env, isManagement, menuOpen]
    );
    const LHome = (
        <LoginHome
            updateColorMode={setColor}
            paletteType={paletteType}
            onLogoClick={() => setTestingMenuOpen(true)}
            newLayout={newLayout}
        />
    );
    const MHome = (
        <ManagementHome
            updateColorMode={setColor}
            paletteType={paletteType}
            version={version}
            onLogoClick={() => setTestingMenuOpen(true)}
            newLayout={newLayout}
            menuOpen={menuOpen}
            setMenuOpen={toggleMenuOpen}
        />
    );

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {globalStyles}
                <Suspense fallback={<Loading />}>
                    <Maybe
                        visible={
                            env.environment !== otherEnv.environment &&
                            (env.environment === "" ||
                                otherEnv.environment === "")
                        }>
                        <TestingMenu
                            open={testingMenuOpen}
                            setOpen={setTestingMenuOpen}
                            toggleColor={toggleColor}
                            environment={env.environment}
                            newTheme={newLayout}
                            toggleNewTheme={toggleNewTheme}
                        />
                    </Maybe>
                    <Routes>
                        <Route
                            path=":url*(/+)"
                            element={
                                <Navigate replace to={pathname.slice(0, -1)} />
                            }
                        />
                        <Route path="/" element={<RedirectToManagement />} />
                        <Route
                            caseSensitive
                            path="/ExternalLogin/:account"
                            element={<ExternalLogin />}
                        />
                        <Route
                            caseSensitive
                            path="/PrintReport"
                            element={<ReportPrint />}
                        />
                        <Route
                            caseSensitive
                            path="/Management/*"
                            element={<ManagementRoute element={MHome} />}
                        />
                        <Route element={LHome} path="*" />
                    </Routes>
                </Suspense>
            </ThemeProvider>
        </CacheProvider>
    );
}
