import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./Common/i18n";
import { ErrorHandler } from "./Common/JavascriptErrorHandling";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ErrorHandler>
            <App />
        </ErrorHandler>
    </BrowserRouter>,
    rootElement
);
