import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        //Uncomment to test French language
        //lng: "fr",
        supportedLngs: ["en", "fr"],
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/Translations/{{lng}}.json",
        },
        debug: false,
    });

export default i18next;
