import i18next from "i18next";
import Cookies from "universal-cookie";
import { callRefresh } from "../Common/CallApi";
import { IdToken } from "../Common/IdToken";
import { getLoginUrl, getMgmtUrl } from "../Common/Urls";
import callApi from "./CallApi";

function dec2hex(dec: number): string {
    return ("0" + dec.toString(16)).substr(-2);
}

function generateCodeVerifier(): string {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join("");
}

function sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a: ArrayBuffer): string {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

async function generateCodeChallengeFromVerifier(v: string): Promise<string> {
    var hashed = await sha256(v);
    var base64encoded = base64urlencode(hashed);
    return base64encoded;
}

type ClientIdReturn = {
    clientId: string;
};

// Ensure that if a multiple redirects are called,
// only the first one actually does a redirect
let ignoreRedirect = false;
export async function redirectToLogin(): Promise<void> {
    if (!ignoreRedirect) {
        ignoreRedirect = true;
        const pkce = generateCodeVerifier();
        sessionStorage.setItem("pkceSource", pkce);
        const pkceChallenge = await generateCodeChallengeFromVerifier(pkce);
        const clientIdCall = await callApi<ClientIdReturn>(
            "/api/ManagementClientId",
            "GET"
        );
        const oldTarget = sessionStorage.getItem("targetUrl");
        if (oldTarget == null) {
            sessionStorage.setItem(
                "targetUrl",
                window.location.pathname +
                    window.location.search +
                    window.location.hash
            );
        }
        if (!clientIdCall.ok) return;
        const cookies = new Cookies();
        const params = new URLSearchParams();
        params.append("scope", "openid email");
        params.append("response_type", "code");
        params.append("client_id", clientIdCall.data.clientId);
        params.append("redirect_uri", `${await getMgmtUrl()}/Management`);
        params.append("code_challenge", pkceChallenge);
        params.append("code_challenge_method", "S256");
        params.append("response_mode", "fragment");
        params.append("allow_cancel", "false");
        params.append("deviceId", cookies.get("deviceId"));
        params.append("lng", i18next.language ?? "en");
        const url = `${await getLoginUrl()}/api/Authenticate?${params.toString()}`;
        window.location.href = url;
    }
}

function toArray<T>(item: any): T[] {
    if (item == null) return [];
    if (!Array.isArray(item)) return [item as T];
    else return item as T[];
}

const requiredFields = ["sub", "user", "account"];

function parseIdToken(input: string): IdToken | null {
    try {
        const obj = JSON.parse(input);
        obj.master = obj.master ?? false;
        obj.mfa_time = obj.mfa_time ?? null;
        obj.mfa_type = obj.mfa_type ?? null;
        obj.roles = toArray(obj.roles);
        obj.email_alt = toArray(obj.email_alt);
        if (requiredFields.some((x) => obj[x] == null)) return null;
        return obj as IdToken;
    } catch {
        return null;
    }
}

export async function getIdToken(
    allowRefresh: boolean = true
): Promise<IdToken | null> {
    var idToken: IdToken | null;
    try {
        const authString = sessionStorage.getItem("authorization");
        if (authString == null) {
            const cookies = new Cookies();
            idToken = parseIdToken(
                cookies.get("id_token", { doNotParse: true })
            );
        } else idToken = parseIdToken(authString);
    } catch {
        idToken = null;
    }
    const unixTime = Math.floor(Date.now() / 1000);
    if (
        idToken != null &&
        unixTime < idToken.exp &&
        idToken.iss === (await getLoginUrl())
    ) {
        return idToken;
    }
    if (allowRefresh) {
        const reauthorized = await callRefresh(redirectToLogin);
        if (reauthorized) return getIdToken(false);
    }
    return null;
}

export async function exchangeCode(authCode: string) {
    const clientIdRequest = await callApi<ClientIdReturn>(
        "/api/ManagementClientId"
    );
    if (!clientIdRequest.ok) return false;
    var searchParams = new URLSearchParams({
        grant_type: "authorization_code",
        code: authCode,
        redirect_uri: `${await getMgmtUrl()}/Management`,
        client_id: clientIdRequest.data.clientId,
        code_verifier: sessionStorage.getItem("pkceSource") ?? "",
    });
    sessionStorage.removeItem("pkceSource");
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: searchParams.toString(),
    };
    const response = await fetch(
        `${await getLoginUrl()}/api/Token`,
        requestOptions
    );
    let data = null;
    if (response.ok) {
        data = await response.json();
        const idJwt = parseJwt(data.id_token);
        const cookies = new Cookies();
        if (idJwt != null) {
            sessionStorage.setItem("authorization", JSON.stringify(idJwt));
            sessionStorage.setItem("refresh", data.refresh_token);
            cookies.set("authorization", data.id_token, {
                path: `/${idJwt["account"]}/api`,
                sameSite: "strict",
                secure: true,
            });
            if (
                cookies.get("deviceId") === undefined ||
                cookies.get("deviceId") !== data.device_id
            ) {
                const forever = new Date(Date.now());
                forever.setFullYear(forever.getFullYear() + 1);
                cookies.set("deviceId", data.device_id, {
                    path: "/",
                    sameSite: "strict",
                    secure: true,
                    expires: forever,
                });
            }
            cookies.set("id_token", JSON.stringify(idJwt), {
                path: "/Management",
                sameSite: "strict",
            });
        }
        return true;
    }
    return false;
}

function parseJwt(token: string): IdToken | null {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return parseIdToken(jsonPayload);
}
