import * as React from "react";
import { Empty } from "./Empty";

export default function Maybe(props: {
    visible: boolean;
    children?: React.ReactNode;
}) {
    return props.visible ? (
        <React.Fragment>{props.children}</React.Fragment>
    ) : (
        <Empty />
    );
}
