import React from "react";
import { emptyAsyncFunction } from "./EmptyFunction";
import ErrorBox from "./Error";

export async function handlePromiseError(error: any): Promise<string | null> {
    let retVal: string | null = null;
    let fn: () => Promise<void> = emptyAsyncFunction;
    try {
        if (error instanceof Error) {
            retVal = error.message;
            fn = () => handleUncaughtError(error.message, error);
        } else if (typeof error === "string") {
            retVal = error;
            fn = () => handleUncaughtError(error, new Error());
        } else {
            retVal = "Uncaught Error";
            fn = () => handleUncaughtError("Uncaught Error", new Error());
        }
    } catch {
        /* ignore errors here*/
    }
    if (retVal?.startsWith("__")) {
        return retVal.substring(2);
    }
    // only call fn() if retVal does not start with __
    await fn();
    return retVal;
}

export async function handleUncaughtError(event: string, error: Error) {
    if (
        (event.includes("is not valid JSON") ||
            event.includes("after JSON") ||
            event.includes("JSON.parse")) &&
        window.confirm("Browser cache issue detected. Clear browser cache?")
    ) {
        const requestOptions = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Methods": "POST",
            },
        };
        await fetch("/api/ClearCache", requestOptions);
        window.location.reload();
    } else {
        const body = new URLSearchParams();
        body.append("msg", event);
        body.append("stack", error?.stack ?? "");
        const requestOptions = {
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Methods": "POST",
            },
            body: body.toString(),
        };
        try {
            await fetch("/api/JavascriptError", requestOptions);
        } catch {
            /* ignore errors in the error handler */
        }
    }
}

export function handleAndThrowPromise(error: PromiseRejectionEvent) {
    handlePromiseError(error).then((p) => {
        throw new Error(p != null ? "__" + p : "Unknown Error");
    });
}

// Must be done in class style for componentDidCatch
export class ErrorHandler extends React.Component<
    { children: any },
    { error: string | null }
> {
    constructor(props: { children: any }) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error: error.message };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        handleUncaughtError(error.message, error);
    }

    componentDidMount() {
        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            handlePromiseError(event.reason).then((p) => {
                if (p != null) {
                    this.setState({ error: p });
                }
            });
        };
    }

    componentWillUnmount() {
        window.onunhandledrejection = null;
    }

    render() {
        if (this.state.error != null) {
            return <ErrorBox error={this.state.error} />;
        }
        return this.props.children;
    }
}
