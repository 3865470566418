import { Wampy } from "./Wampy";

function shouldSsoBeSkipped() {
    if (navigator.userAgent.match(/iPhone|Android/i)) {
        return true;
    }
    if (sessionStorage.getItem("session_token") === "0") {
        return true;
    }
    return false;
}

function Sso_Get_Session_Token(connection) {
    return new Promise(function (resolve, reject) {
        let session_token = sessionStorage.getItem("session_token");
        if (session_token) {
            resolve(session_token);
        } else {
            connection.call(
                "reynolds.sys.sso.get_token",
                null,
                {
                    onSuccess: function (res) {
                        session_token = res.argsDict["token"];
                        sessionStorage.setItem("session_token", session_token);
                        resolve(session_token);
                    },
                    onError: function (err) {
                        var challenge = err.argsDict["token"];

                        if (challenge) {
                            var iframe = document.createElement("iframe");
                            iframe.height = "1";
                            iframe.width = "1";
                            iframe.style = "visibility:hidden";

                            iframe.setAttribute("src", "reysso:" + challenge);

                            document.body.appendChild(iframe);
                            setTimeout(
                                function (timeoutConnection) {
                                    iframe.setAttribute("src", "about:blank");
                                    timeoutConnection.call(
                                        "reynolds.sys.sso.get_token",
                                        { token: challenge },
                                        {
                                            onSuccess: function (res) {
                                                session_token =
                                                    res.argsDict["token"];
                                                sessionStorage.setItem(
                                                    "session_token",
                                                    session_token
                                                );
                                                resolve(session_token);
                                            },
                                            onError: function () {
                                                reject(
                                                    "Could not get session token"
                                                );
                                            },
                                        }
                                    );
                                },
                                250,
                                connection,
                                iframe
                            );
                        } else {
                            reject("Could not get session token");
                        }
                    },
                },
                { timeout: 2000 }
            );
        }
    });
}

/**
 * Sso_Add
 * @param {string} [ProviderId]
 * @param {string} [ProviderName]
 * @param {string} [UserId]
 * @param {string} [UserName]
 * @param {string} [GeneratedToken]
 * @param {string} [Timestamp]
 * @param {string} [DeviceId]
 * @param {string} [mfaCookie]
 */
export function Sso_Add(
    ProviderId,
    ProviderName,
    UserId,
    UserName,
    GeneratedToken,
    Timestamp,
    DeviceId,
    MfaCookie
) {
    if (shouldSsoBeSkipped()) {
        console.log("Sso_Add:skip");
        return new Promise((resolve, reject) => resolve());
    }
    console.log("Sso_Add mfaCookie=" + MfaCookie);
    return new Promise((resolve, reject) => {
        Sso_Call((connection, token, callback) => {
            return connection.call(
                "reynolds.sys.sso.add",
                {
                    token: token,
                    providerID: ProviderId,
                    payload: {
                        ProviderName: ProviderName,
                        UserID: UserId,
                        UserName: UserName,
                        Timestamp: Timestamp,
                        Token: GeneratedToken,
                        DeviceId: DeviceId,
                        MfaCookie: MfaCookie
                    },
                },
                callback,
                { timeout: 2000 }
            );
        })
            .then(resolve, (err) => {
                console.log(err.message);
                resolve();
            })
            .catch((error) => {
                console.log(error.message);
                resolve();
            });
    });
}

/**
 * Error callback
 *
 * @callback errorCallback
 */

/**
 * Sso_Add
 * @param {errorCallback} error_proc
 */
export function Sso_Get(error_proc) {
    if (shouldSsoBeSkipped()) {
        return new Promise((resolve, reject) => resolve());
    }
    return new Promise(function (resolve, reject) {
        Sso_Call(
            function (connection, token, callback) {
                return connection.call(
                    "reynolds.sys.sso.get",
                    { token: token},
                    callback,
                    { timeout: 2000 }
                );
            },
            null,
            error_proc
        )
            .then(resolve, (err) => {
                console.log(err.message);
                resolve({});
            })
            .catch((error) => {
                console.log(error.message);
                reject({});
            });
    });
}

/**
 * Sso_Remove
 * @param {string} [ProviderId]
 * @param {string} [href]
 */
export function Sso_Remove(ProviderId, href) {
    if (shouldSsoBeSkipped()) {
        console.log("Sso_Remove skip");
        return new Promise((resolve, reject) => resolve());
    }
    console.log("Sso_Remove ProviderId=" + ProviderId);
    return new Promise(function (resolve, reject) {
        Sso_Call(
            function (connection, token, callback) {
                return connection.call(
                    "reynolds.sys.sso.remove",
                    {
                        token: token,
                        providerID: ProviderId,
                    },
                    callback,
                    { timeout: 2000 }
                );
            },
            null,
            href
        )
            .then(resolve, (err) => {
                console.log(err.message);
                resolve();
            })
            .catch((error) => {
                console.log(error.message);
                resolve();
            });
    });
}

const Sso_Access = {
    Sso_Add,
    Sso_Get,
    Sso_Remove,
};

export default Sso_Access;

function Sso_Call(call_proc, href, error_proc) {
    var errorDisplayed = false;
    return new Promise(function (resolve, reject) {
        var url = "wss://sso.dms.reyrey.net:9002";

        var connection = new Wampy(url, {
            realm: "reynolds.sys",
            maxRetries: 1,
            autoReconnect: false,
            timeout: 2000,
            onError: function (error) {
                if (
                    !errorDisplayed &&
                    error_proc &&
                    !(
                        typeof error.error == "string" &&
                        error.error.includes("logon_timeout")
                    )
                ) {
                    error_proc();
                    errorDisplayed = true;
                }
                if (href) {
                    window.location.href = href;
                }
                reject(error);
            },
            onConnect: function () {
                Sso_Get_Session_Token(connection).then(
                    function (token) {
                        call_proc(connection, token, {
                            onSuccess: function (res) {
                                resolve(res);
                            },
                            onError: function (err) {
                                var challenge = !!err.kwargs
                                    ? err.kwargs["token"]
                                    : err.argsDict["token"];

                                if (challenge) {
                                    var iframe =
                                        document.createElement("iframe");
                                    iframe.height = "1";
                                    iframe.width = "1";
                                    iframe.style = "visibility:hidden";
                                    iframe.setAttribute(
                                        "src",
                                        "reysso:" + challenge
                                    );

                                    document.body.appendChild(iframe);
                                    setTimeout(
                                        function (
                                            timeoutConnection,
                                            timeoutChallenge
                                        ) {
                                            iframe.setAttribute(
                                                "src",
                                                "about:blank"
                                            );
                                            const session_token =
                                                timeoutChallenge;
                                            sessionStorage.setItem(
                                                "session_token",
                                                session_token
                                            );
                                            call_proc(
                                                timeoutConnection,
                                                session_token,
                                                {
                                                    onSuccess: function (res) {
                                                        resolve(res);
                                                    },
                                                    onError: function (
                                                        timeoutErr
                                                    ) {
                                                        connection.abort();
                                                        reject(timeoutErr);
                                                    },
                                                }
                                            );
                                        },
                                        250,
                                        connection,
                                        challenge,
                                        iframe
                                    );
                                } else {
                                    connection.abort();
                                    reject(err);
                                }
                            },
                        });
                    },
                    function (err) {
                        console.log(err.message);
                        connection.abort();
                        reject(err);
                    }
                );
            },
        });
    });
}
