import { callApi as callApiFromCommon, CallApiReturn } from "../Common/CallApi";
import { redirectToLogin } from "./Authenticate";

export default function callApi<ReturnType>(
    url: string,
    verb: string = "GET",
    body: string | null = null,
    timeout: number = 30000,
    headers?: { [key: string]: string },
    cache?: RequestCache
): Promise<CallApiReturn<ReturnType>> {
    return callApiFromCommon(
        url,
        verb,
        body,
        timeout,
        redirectToLogin,
        headers,
        cache
    );
}
