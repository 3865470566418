import { Lock } from "semaphore-async-await";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { Sso_Add, Sso_Get, Sso_Remove } from "../Login/SsoAccess";
import { redirectToLogin } from "../Management/Authenticate";
import { getLoginUrl } from "./Urls";

export type CallApiReturn<ReturnType> =
    | {
          ok: false;
          headers: Record<string, string>;
          status: number;
          errorText: string;
      }
    | {
          ok: true;
          headers: Record<string, string>;
          data: ReturnType;
      };

export async function callApi<ReturnType>(
    url: string,
    verb: string = "GET",
    body: string | null = null,
    timeout: number = 30000,
    onReauthFail: (() => Promise<void>) | null = null,
    headers?: { [key: string]: string },
    cache?: RequestCache,
    allowReauth: boolean = true
): Promise<CallApiReturn<ReturnType>> {
    const csrf = await getCsrf();
    if (csrf == null)
        return {
            ok: false,
            status: 0,
            errorText: "Error getting token",
            headers: {},
        };

    const controller = new AbortController();

    const requestOptions: RequestInit = {
        signal: controller.signal,
        method: verb,
        headers: {
            csrfToken: csrf,
            "content-type":
                verb === "PATCH"
                    ? "application/json-patch+json"
                    : "application/json",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH",
            ...headers,
        },
        body: body,
        cache: cache,
    };
    const doFetch = async () => {
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        let response;
        try {
            response = await fetch(url, requestOptions);
        } catch {
            clearTimeout(timeoutId);
            return null;
        }
        clearTimeout(timeoutId);
        return response;
    };

    const cookies = new Cookies();
    if (
        cookies.get("deviceId") === "" ||
        cookies.get("deviceId") === undefined
    ) {
        //check reywamp for deviceId
        const ssoData: [
            {
                payload: {
                    ProviderName: string;
                    UserId: string;
                    UserName: string;
                    Timestamp: string;
                    Token: string;
                    MfaCookie: string;
                    DeviceId: string;
                };
                providerID: string;
                userId: string;
                userName: string;
                timestamp: string;
            }
        ] = (await Sso_Get(ssoFailAlert))?.argsList ?? [];

        const matchDeviceId = ssoData.find(
            (sso) =>
                sso.payload.ProviderName === "dlrSecured Identity Login" &&
                sso.payload.DeviceId != null &&
                sso.payload.DeviceId !== ""
        );

        const forever = new Date(Date.now());
        forever.setFullYear(forever.getFullYear() + 1);

        //if value found in reywamp, create cookie with this value
        if (matchDeviceId) {
            cookies.set("deviceId", matchDeviceId.payload.DeviceId, {
                path: "/",
                sameSite: "strict",
                secure: true,
                expires: forever,
            });
        } else {
            //if no value in reywamp, create new id and use it in the cookie
            const deviceIdGuid = uuidv4();
            cookies.set("deviceId", deviceIdGuid, {
                path: "/",
                sameSite: "strict",
                secure: true,
                expires: forever,
            });

            //update reywamp entries (if any) with device id
            ssoData
                .filter(
                    (x) =>
                        x.payload?.ProviderName === "dlrSecured Identity Login"
                )
                .forEach(async (x) => {
                    try {
                        await Sso_Remove(x.providerID);

                        await Sso_Add(
                            x.providerID,
                            "dlrSecured Identity Login",
                            x.userId,
                            x.userName,
                            x.payload.Token,
                            x.timestamp,
                            deviceIdGuid,
                            x.payload.MfaCookie
                        );
                    } catch {}
                });
        }
    }
    let retVal = await handleCall<ReturnType>(
        doFetch,
        onReauthFail,
        allowReauth
    );
    if (!retVal.ok && retVal.status >= 600 && verb === "GET") {
        caches?.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
        });
        retVal = await handleCall<ReturnType>(
            doFetch,
            onReauthFail,
            allowReauth
        );
    }
    return retVal;
}

function ssoFailAlert() {
    console.warn("CallApi SSO Failure");
}

async function handleCall<ReturnType>(
    doFetch: () => Promise<Response | null>,
    onReauthFail: (() => Promise<void>) | null,
    allowReauth: boolean
): Promise<CallApiReturn<ReturnType>> {
    const response = await doFetch();
    if (response == null) {
        return {
            ok: false,
            headers: {},
            status: 0,
            errorText: "Timeout waiting for response",
        };
    }

    if (!response.ok && response.status === 401 && allowReauth) {
        const reauthorized = await callRefresh(onReauthFail);
        if (reauthorized)
            return handleCall<ReturnType>(doFetch, onReauthFail, false);
    }
    const headers = getHeaders(response);
    if (response.ok) {
        let data = {};
        if (response.status !== 204) {
            try {
                data = await response.json();
            } catch {
                return {
                    ok: false,
                    headers: headers,
                    errorText: "Could not deserialize response",
                    status: 600,
                };
            }
        }
        return {
            ok: true,
            headers: headers,
            data: data as ReturnType,
        };
    } else {
        let errorText: string | null = await response.text();
        if (errorText === "") {
            errorText = null;
        } else {
            try {
                const errorObject = JSON.parse(errorText);
                if (errorObject["detail"] != null) {
                    errorText = errorObject["detail"];
                }
            } catch {}
        }
        return {
            ok: false,
            headers: headers,
            status: response.status,
            errorText: `${response.status} - ${
                errorText ?? response.statusText
            }`,
        };
    }
}

export async function callRefresh(
    onFail: (() => Promise<void>) | null
): Promise<boolean> {
    const refreshToken = sessionStorage.getItem("refresh");
    if (refreshToken == null) {
        await redirectToLogin();
        return false;
    }
    const clientIdRequest = await callApi<{ clientId: string }>(
        "/api/ManagementClientId",
        "GET"
    );
    if (!clientIdRequest.ok) return false;
    const searchParams = new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: clientIdRequest.data.clientId,
    });
    const url = `${await getLoginUrl()}/api/Token`;
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: searchParams.toString(),
    };
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        try {
            const data = await response.json();
            if (data.error != null) {
                if (onFail != null) {
                    await onFail();
                }
                return false;
            } else {
                const idJwt = parseJwt(data.id_token);
                const cookies = new Cookies();

                sessionStorage.setItem("authorization", JSON.stringify(idJwt));
                sessionStorage.setItem("refresh", data.refresh_token);
                cookies.set("authorization", data.id_token, {
                    path: `/${idJwt["account"]}/api`,
                    sameSite: "strict",
                    secure: true,
                });
                cookies.set("id_token", JSON.stringify(idJwt), {
                    path: "/Management",
                    sameSite: "strict",
                    secure: true,
                });
                return true;
            }
        } catch {
            if (onFail != null) {
                await onFail();
            }
            return false;
        }
    } else {
        if (onFail != null) {
            await onFail();
        }
        return false;
    }
}

function parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload);
}

function getHeaders(response: Response): Record<string, string> {
    const headers: Record<string, string> = {};
    if (response.headers != null) {
        response.headers.forEach((value, key) => (headers[key] = value));
    }
    return headers;
}

const l = new Lock();

async function getCsrf(): Promise<string | null> {
    await l.acquire();

    const cookies = new Cookies();
    const token = cookies.get("csrf");
    if (token != null) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );
        try {
            const csrf = JSON.parse(jsonPayload);
            // if expires *soon*, get new one
            const unixTimeFiveMinutesFromNow =
                Math.floor(Date.now() / 1000) + 5 * 60;
            if (unixTimeFiveMinutesFromNow < csrf.exp) {
                l.release();
                return token;
            }
        } catch {
            /* fall through */
        }
    }
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
    try {
        const csrfResponse = await fetch("/api/CSRF", {
            signal: controller.signal,
            method: "POST",
        });
        l.release();
        if (csrfResponse.ok) return cookies.get("csrf");
        return null;
    } catch {}
    clearTimeout(timeoutId);
    l.release();
    return null;
}
