import { Check } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useCallback, useEffect, useState } from "react";
import { IdToken } from "./Common/IdToken";
import { handlePromiseError } from "./Common/JavascriptErrorHandling";
import Maybe from "./Common/Maybe";
import { getIdToken } from "./Management/Authenticate";

type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

async function toggleRole(role: string) {
    const idToken = await getIdToken(false);
    if (idToken == null) return;
    if (idToken.roles.some((x) => x === role)) {
        idToken.roles = idToken.roles.filter((x) => x !== role);
    } else {
        idToken.roles.push(role);
    }
    sessionStorage.setItem("authorization", JSON.stringify(idToken));
}

async function toggleMaster() {
    const idToken = await getIdToken(false);
    if (idToken == null) return;
    idToken.master = !idToken.master;
    sessionStorage.setItem("authorization", JSON.stringify(idToken));
}

function isMaster(idToken: IdToken | null) {
    if (idToken == null) return false;
    return idToken.master ?? false;
}

function hasRole(idToken: IdToken | null, role: string) {
    if (idToken == null) return false;
    return idToken.roles.some((x) => x === role);
}

function PermissionSection(props: { idToken: IdToken | null }) {
    return (
        <React.Fragment>
            <Maybe
                visible={props.idToken != null && !props.idToken.cross_account}>
                <PermissionsVisible idToken={props.idToken!} />
            </Maybe>
            <Maybe
                visible={
                    props.idToken != null &&
                    props.idToken.cross_account === true
                }>
                <PermissionsDisabled />
            </Maybe>
        </React.Fragment>
    );
}

function MaybeCheckListItemIcon(props: { visible: boolean }) {
    return (
        <ListItemIcon>
            <Maybe visible={props.visible}>
                <Check />
            </Maybe>
        </ListItemIcon>
    );
}

function PermissionsVisible(props: { idToken: IdToken }) {
    const reyUserCallback = useCallback(() => {
        toggleMaster();
        document.location.reload();
    }, []);
    const enterpriseAdminCallback = useCallback(() => {
        toggleRole("ENTERPRISEADMIN");
        document.location.reload();
    }, []);
    const asstEnterpriseAdminCallback = useCallback(() => {
        toggleRole("ASSISTANTADMIN");
        document.location.reload();
    }, []);
    const accountsAdminCallback = useCallback(() => {
        toggleRole("ACCOUNTSADMIN");
        document.location.reload();
    }, []);
    const documentationCallback = useCallback(() => {
        toggleRole("DOCUMENTATION");
        document.location.reload();
    }, []);
    const resetPermissionsCallback = useCallback(async () => {
        sessionStorage.removeItem("authorization");
        await getIdToken(false);
        document.location.reload();
    }, []);
    return (
        <React.Fragment>
            <ListItem>
                <ListItemText primary="Permissions (UI Only)" />
            </ListItem>
            <ListItem button onClick={reyUserCallback}>
                <MaybeCheckListItemIcon visible={isMaster(props.idToken!)} />
                <ListItemText primary="Reynolds User" />
            </ListItem>
            <ListItem button onClick={enterpriseAdminCallback}>
                <MaybeCheckListItemIcon
                    visible={hasRole(props.idToken, "ENTERPRISEADMIN")}
                />
                <ListItemText primary="Enterprise Admin" />
            </ListItem>
            <ListItem button onClick={asstEnterpriseAdminCallback}>
                <MaybeCheckListItemIcon
                    visible={hasRole(props.idToken, "ASSISTANTADMIN")}
                />
                <ListItemText primary="Assistant Admin" />
            </ListItem>
            <ListItem button onClick={accountsAdminCallback}>
                <MaybeCheckListItemIcon
                    visible={hasRole(props.idToken, "ACCOUNTSADMIN")}
                />
                <ListItemText primary="Accounts Admin" />
            </ListItem>
            <ListItem button onClick={documentationCallback}>
                <MaybeCheckListItemIcon
                    visible={hasRole(props.idToken, "DOCUMENTATION")}
                />
                <ListItemText primary="Documentation Editor" />
            </ListItem>
            <ListItem button onClick={resetPermissionsCallback}>
                <ListItemIcon />
                <ListItemText primary="Reset Permissions" />
            </ListItem>
        </React.Fragment>
    );
}

function PermissionsDisabled() {
    return (
        <ListItem disabled>
            <ListItemText primary="Permissions unavailable while in a customer account" />
        </ListItem>
    );
}

function maybeBooleanStringToString(input: string | null) {
    if (input == null) return "Not Set";
    else if (input === "true") return "Yes";
    else return "No";
}

function OtherSection() {
    const [staySignedInValue, setStaySignedInValue] = useState<string | null>(
        localStorage.getItem("staySignedIn")
    );
    const clearStaySignedIn = useCallback(() => {
        setStaySignedInValue(null);
        localStorage.removeItem("staySignedIn");
        document.location.reload();
    }, [setStaySignedInValue]);
    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={`Stay Signed In: ${maybeBooleanStringToString(
                        staySignedInValue
                    )}`}
                />
            </ListItem>
            <ListItem button onClick={clearStaySignedIn}>
                <MaybeCheckListItemIcon visible={false} />
                <ListItemText primary="Reset Browser Stay Signed In" />
            </ListItem>
        </React.Fragment>
    );
}

type EnvironmentDescription = "test" | "";

function ColorSection(props: {
    toggleColor: (env: EnvironmentDescription) => void;
    environment: EnvironmentDescription;
    toggleNewTheme: (env: boolean) => void;
    newTheme: boolean;
}) {
    return (
        <React.Fragment>
            <ListItem>
                <ListItemText primary="Color" />
            </ListItem>
            <ListItem button onClick={() => props.toggleColor("")}>
                <MaybeCheckListItemIcon visible={props.environment === ""} />
                <ListItemText primary="Production" />
            </ListItem>
            <ListItem button onClick={() => props.toggleColor("test")}>
                <MaybeCheckListItemIcon visible={props.environment !== ""} />
                <ListItemText primary="Testing" />
            </ListItem>
            <ListItem>
                <ListItemText primary="New Layout" />
            </ListItem>
            <ListItem button onClick={() => props.toggleNewTheme(true)}>
                <MaybeCheckListItemIcon visible={props.newTheme} />
                <ListItemText primary="Enabled" />
            </ListItem>
            <ListItem button onClick={() => props.toggleNewTheme(false)}>
                <MaybeCheckListItemIcon visible={!props.newTheme} />
                <ListItemText primary="Disabled" />
            </ListItem>
        </React.Fragment>
    );
}

export function TestingMenu(props: {
    open: boolean;
    setOpen: Setter<boolean>;
    toggleColor: (env: EnvironmentDescription) => void;
    environment: EnvironmentDescription;
    toggleNewTheme: (env: boolean) => void;
    newTheme: boolean;
}) {
    const setOpen = props.setOpen;
    const toggleDrawer = useCallback(
        (event: any) => {
            if (
                event.type === "keydown" &&
                (event.key === "Tab" || event.key === "Shift")
            ) {
                return;
            }
            setOpen(false);
        },
        [setOpen]
    );
    const [idToken, setIdToken] = useState<IdToken | null>(null);
    useEffect(() => {
        async function getIdTokenEffect() {
            const idTokenInner = await getIdToken(false);
            if (idToken?.exp !== idTokenInner?.exp) {
                setIdToken(idTokenInner);
            }
        }
        getIdTokenEffect().catch((error) =>
            handlePromiseError(error).then((p) => {
                throw new Error(p != null ? "__" + p : "Unknown Error");
            })
        );
    });

    return (
        <Drawer anchor={"left"} open={props.open} onClose={toggleDrawer}>
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer}
                onKeyDown={toggleDrawer}>
                <List>
                    <ColorSection
                        environment={props.environment}
                        toggleColor={props.toggleColor}
                        newTheme={props.newTheme}
                        toggleNewTheme={props.toggleNewTheme}
                    />
                    <PermissionSection idToken={idToken} />
                    <OtherSection />
                </List>
            </Box>
        </Drawer>
    );
}
