export async function getLoginInformation(): Promise<{
    login: string;
    mgmt: string;
    clientId: string;
}> {
    let info = sessionStorage.getItem("loginInformation");
    if (info != null) {
        return JSON.parse(info);
    }
    const response = await fetch("/api/ManagementClientInformation");
    if (response.ok) {
        const text = await response.text();
        sessionStorage.setItem("loginInformation", text);
        return JSON.parse(text) as any;
    }
    return { login: "", mgmt: "", clientId: "" };
}

export async function getLoginUrl(): Promise<string> {
    return (await getLoginInformation()).login;
}

export async function getMgmtUrl(): Promise<string> {
    return (await getLoginInformation()).mgmt;
}
