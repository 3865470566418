import { Collapse, SxProps } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as React from "react";

type ErrorProps = {
    error: string | null;
    dismiss?: (event: React.SyntheticEvent) => void;
    sx?: SxProps;
};

export default function ErrorBox(props: ErrorProps) {
    return (
        <Collapse in={props.error != null}>
            <Alert
                severity="error"
                sx={{
                    width: "100%",
                    marginBottom: props.error != null ? ".5rem" : undefined,
                    whiteSpace: "pre-line",
                    ...props.sx,
                }}
                onClose={props.dismiss}>
                {props.error}
            </Alert>
        </Collapse>
    );
}
