import { handleUncaughtError } from "./JavascriptErrorHandling";

type WrappedFetchFailureReturn = {
    ok: false;
    errorText: string;
};

type WrappedFetchSuccessReturn<T> = {
    ok: true;
    json: T;
};

export type WrappedFetchReturn<T> =
    | WrappedFetchSuccessReturn<T>
    | WrappedFetchFailureReturn;

// Only use when call api is not appropriate
// which should be when CSRF tokens are not needed
// and should not be done with anything that modifies
// a resource
export async function wrappedJsonFetch<T>(
    input: URL | RequestInfo,
    init?: RequestInit | undefined
): Promise<WrappedFetchReturn<T>> {
    const initialResponse = await fetch(input, init);
    if (initialResponse.ok) {
        try {
            const data = (await initialResponse.json()) as T;
            return {
                ok: true,
                json: data,
            };
        } catch {
            // Something has gone seriously wrong if we reach this point.
            // As best as I can determine, the web view 2 is having its cache
            // corrupted, resulting in invalid "responses" to certain requests.
            // Force the cache to empty and then retry the request.
            // If it still fails in the same way, report the invalid response
            // to the server.
            caches?.keys().then((names) => {
                names.forEach((name) => caches.delete(name));
            });
            const secondResponse = await fetch(input, init);
            if (secondResponse.ok) {
                const clonedRespone = secondResponse.clone();
                try {
                    const data = (await secondResponse.json()) as T;
                    return {
                        ok: true,
                        json: data,
                    };
                } catch (e: unknown) {
                    const text = await clonedRespone.text();
                    if (e instanceof Error)
                        handleUncaughtError(
                            `Problem parsing json response [${text}]`,
                            e
                        );
                    else
                        handleUncaughtError(
                            `Problem parsing json response [${text}]`,
                            new Error()
                        );
                    return {
                        ok: false,
                        errorText: "Problem parsing json response",
                    };
                }
            }
        }
    }
    return {
        ok: false,
        errorText: initialResponse.statusText,
    };
}
